import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
    {
        path: '/loginmap',
        component: () => import('@/views/LoginMap.vue')
    },
    {
        path: '/',
        component: () => import('@/views/AppWithHeaderFooter.vue'),
        children: [
            {
                path: '/',
                component: () => import('@/views/Home.vue')
            },
            {
                path: '/opendata',
                component: () => import('@/views/OpenData.vue')
            },
            {
                path: '/datenschutz',
                component: () => import('@/views/Datenschutz.vue')
            },
            {
                path: '/bestellformular',
                component: () => import('@/views/Bestellformular.vue')
            },
            {
                path: '/hilfe',
                component: () => import('@/views/Hilfe.vue')
            },
            {
                path: '/barrierefreiheit',
                component: () => import('@/views/Barrierefreiheit.vue')
            },
            {
                path: '/wartung',
                component: () => import('@/views/Wartung.vue')
            },
            // // nur LVN
            // {
            //     path: '/meldeseite',
            //     component: () => import('@/views/Katasteramt_Meldungen.vue')
            // },
            // // nur LVN
            // {
            //     path: '/meldeseite/:kn',
            //     component: () => import('../views/Katasteramt_Meldungen.vue'),
            //     props: (route) => ({
            //         kn: route.params.kn
            //     })
            // },
            // // nur LVN
            // {
            //     path: '/betrieb-meldeseite',
            //     component: () => import('@/views/Betrieb_Meldungen.vue')
            // },
            // // nur LVN
            // {
            //     path: '/betrieb-konfiguration',
            //     component: () => import('@/views/Betrieb_Konfiguration.vue')
            // }
        ]
    },
    // {
    //     // nur LVN
    //     path: '/intern',
    //     component: () => import('@/views/AppWithHeader.vue'),
    //     children: [
    //         {
    //             path: '/',
    //             component: () => import('@/views/InternerBereich.vue'),
    //         }]
    // }
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

export default router
